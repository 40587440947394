import { browser } from './data/constants'
import { ICONS } from '../assets/svg/icons'
import { blend } from './utils/blender'

export class Icon {
    public content: HTMLDivElement = document.createElement('div')

    public color: string = '#000000'

    public size: number = 24

    private text: SVGTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text')

    constructor() {
        this.content.classList.add('cursor-pointer')
        this.text.setAttribute('alignment-baseline', 'central')
        this.text.setAttribute('stroke-width', '1')
        this.text.setAttribute('stroke', 'black')
        this.text.setAttribute('fill', 'black')
        this.text.setAttribute('text-anchor', 'middle')
        this.text.setAttribute(
            'font-family',
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji'
        )
        this.text.setAttribute('font-size', '12px')
        this.text.setAttribute('x', '12')
        this.text.setAttribute('y', browser === 'Chrome' || browser === 'Safari' ? '12' : '16')
    }

    public isHidden(): boolean {
        if (this.content.firstElementChild) {
            return this.content.firstElementChild.classList.contains('hidden')
        }
        return false
    }

    public setFill(color: string): this {
        if (color && this.content.firstElementChild) {
            this.color = color
            this.content.firstElementChild.setAttribute('fill', color)
        }
        return this
    }

    public genColoredStroke(): this {
        if (this.content.firstElementChild) {
            const color: string | null = this.content.firstElementChild.getAttribute('fill')
            if (color) {
                const borderColor: string = blend(color, '#000000', 0.3)
                this.content.firstElementChild.setAttribute('stroke', borderColor)

                const textColor: string = blend(color, '#000000', 0.7)
                this.text.setAttribute('stroke', textColor)
                this.text.setAttribute('fill', textColor)
            }
        }
        return this
    }

    public setIcon(name: string): this {
        if (name) {
            if (this.content.firstElementChild) {
                if (this.content.firstElementChild.classList.contains('hidden')) {
                    this.content.innerHTML = ICONS[name]
                    this.content.firstElementChild.classList.add('hidden')
                } else {
                    this.content.innerHTML = ICONS[name]
                }
            } else {
                this.content.innerHTML = ICONS[name]
            }
            this.setSize(this.size)
        }
        return this
    }

    public setSize(size: number): this {
        if (size && this.content.firstElementChild) {
            this.size = size
            this.content.firstElementChild.setAttribute('width', String(size))
            this.content.firstElementChild.setAttribute('height', String(size))
        }
        return this
    }

    public setStroke(color: string): this {
        if (color && this.content.firstElementChild) {
            this.content.firstElementChild.setAttribute('stroke', color)
        }
        return this
    }

    public setFontSize(size: number): this {
        this.text.setAttribute('font-size', `${size}px`)
        return this
    }

    public setStrokeWidth(size: number): this {
        if (this.content.firstElementChild && size) {
            this.content.firstElementChild.setAttribute('stroke-width', String(size))
        }
        return this
    }

    public setText(string: string): this {
        if (this.content.firstElementChild && string) {
            this.text.innerHTML = string
            this.content.firstElementChild.appendChild(this.text)
        }
        return this
    }

    public getText(): string {
        return this.text.innerHTML
    }

    public toggle(): this {
        if (this.content.firstElementChild) {
            this.content.firstElementChild.classList.toggle('hidden')
        }
        return this
    }

    public toggleText(): this {
        if (this.text.style.display !== 'none') {
            this.text.style.display = 'none'
        } else {
            this.text.style.display = 'block'
        }
        return this
    }

    public toString(): string {
        return this.content.innerHTML
    }
}
