import * as Mapbox from 'mapbox-gl'
import { Point } from './point'
import { Points } from './points'
import { Line } from './line'
import { Threshold } from './threshold'
import { Unit } from './unit'

export abstract class ReportBase {
    public information: any = undefined

    public line: Line

    public points: Points = new Points()

    public screenshots: string[] = []

    public currentAverage: number = 0

    public abstract thresholds: CurrentList<Threshold>

    public abstract values: CurrentList<string>

    constructor(map: Mapbox.Map) {
        this.line = new Line(map, this.points.children)
    }

    public abstract getAverage(): number

    public addPointsToMap(map: Mapbox.Map): void {
        this.points.addToMap(map)
        this.points.bound(map)
        this.update(map)

        this.points.children.forEach((point: Point) => {
            point._marker.on('drag', () => {
                if (this.thresholds.current && this.values.current) {
                    this.line.update(map, this.thresholds.current, this.values.current)
                }
            })
        })
    }

    public clean(map: Mapbox.Map): void {
        this.information = undefined
        this.line.clean(map)
        this.points.children.forEach((point: Point) => {
            point.clean()
        })
        this.screenshots = []
    }

    public update(map: Mapbox.Map): void {
        if (this.thresholds.current && this.values.current) {
            this.points.update(this.thresholds.current, this.values.current)
            this.line.update(map, this.thresholds.current, this.values.current)
            this.currentAverage = this.getAverage()
        }
    }
}
