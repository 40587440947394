














































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import imageCompression from 'browser-image-compression'

import IconBase from '@/components/IconBase.vue'

import { Project } from '@/ts/project'

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project

    private mounted() {
        document.body.appendChild(this.$el)
    }

    private async upload(imageFiles: File[]) {
        for (const imageFile of imageFiles) {
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1000,
                useWebWorker: true,
            }

            try {
                const compressedFile = await imageCompression(imageFile, options)

                console.log(imageFile, compressedFile)

                this.project?.reports?.current?.screenshots.push(
                    (await imageCompression.getDataUrlFromFile(compressedFile)) as string
                )
            } catch (error) {
                console.log(error)
            }
        }
    }
}
