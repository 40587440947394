























import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import html2canvas from 'html2canvas'
import imageCompression from 'browser-image-compression'

import { Project } from '@/ts/project'

import Button from './Button.vue'

@Component({
    components: {
        Button,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project

    @Prop()
    private img!: string

    private mounted() {
        document.body.appendChild(this.$el)
    }

    private async add(): Promise<void> {
        if (this.project.reports.current && this.img) {
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1000,
                useWebWorker: true,
            }

            try {
                const compressedFile = await imageCompression(
                    await imageCompression.getFilefromDataUrl(this.img, 'img.png'),
                    options
                )

                this.project?.reports?.current?.screenshots.push(
                    (await imageCompression.getDataUrlFromFile(compressedFile)) as string
                )
            } catch (error) {
                console.log(error)
            }

            this.$emit('done')
        }
    }
}
