var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    inline-block\n",domProps:{"innerHTML":_vm._s(_vm.icon
        .setFill(_vm.fill)
        .setIcon(_vm.name)
        .setSize(_vm.size)
        .setStroke(_vm.stroke)
        .setStrokeWidth(_vm.strokeWidth)
        .setText(_vm.text)
        .toString())}})}
var staticRenderFns = []

export { render, staticRenderFns }