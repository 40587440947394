






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import IconBase from '@/components/IconBase.vue';

import { Project } from '@/ts/project';

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private columns!: string[];
}

