

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { ReportBase } from '@/ts/reportBase';

import Box from '@/components/Box.vue';

import ButtonAverage from './components/ButtonAverage.vue';
import SelectorValues from './components/SelectorValues.vue';
import Table from './components/Table/index.vue';

@Component({
    components: {
        Box,
        ButtonAverage,
        SelectorValues,
        Table,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop({ default: false })
    private startsOpen!: boolean;

    private columns: string[] = [];

    private columnsHistory: string[] = [];

    private mounted(): void {
        if (this.project.reports.current) {
            const report: ReportBase = this.project.reports.current;

            ['D0', 'D200', 'ForceMax'].forEach((value) => {
                if (report.values.list.includes(value)) {
                    this.columns.push(value);
                }
            });

            [0, 1, 2].forEach((value) => {
                if (this.columns.length === value) {
                    this.columns.push(report.values.list[value]);
                }
            });

            this.columnsHistory = [...this.columns.reverse()];

            this.columns.sort((this.project.reports.current as any).sortValues);
        }
    }
}

