import * as MathJS from 'mathjs';

MathJS.createUnit('cmm', '10 um');

export class Unit {
    private _mathJSUnit: MathJS.Unit;

    private _numberUnit: string;

    private _precision: number;

    constructor(number: number, unit: string, precision: number = 1) {
        this._numberUnit = unit;

        this._mathJSUnit = MathJS.unit(number, this.normalizeUnit());

        this._precision = precision;
    }

    public toString(): string {
        return String(`${this.toDisplayedNumber()} ${this._numberUnit}`);
    }

    public toNumber(precision?: number): number {
        if (!precision) {
            precision = this._precision;
        }
        return Number(this._mathJSUnit.toNumber(this.normalizeUnit()).toFixed(this._precision));
    }

    public toDisplayedNumber(locale?: string, precision?: number): string {
        if (!locale) {
            locale = navigator.language;
        }

        if (!precision) {
            precision = this._precision;
        }

        return this._mathJSUnit
            .toNumber(this.normalizeUnit())
            .toLocaleString(locale, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            });
    }

    public toUnit(): string {
        return this._numberUnit;
    }

    public setNumber(number: number): void {
        this._mathJSUnit = MathJS.unit(number, this.normalizeUnit());
    }

    public static changeUnit() {
        // code
    }

    private normalizeUnit(): string {
        switch (this._numberUnit) {
            case '1/100mm':
                return 'cmm';
            default:
                return this._numberUnit;
        }
    }
}
