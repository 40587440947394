































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Factory } from '@/ts/factory';
import { Project } from '@/ts/project';

import IconBase from '@/components/IconBase.vue';

import InterfaceLoaded from './components/InterfaceLoaded/index.vue';
import InterfaceUnloaded from './components/InterfaceUnloaded/index.vue';

@Component({
    components: {
        IconBase,
        InterfaceLoaded,
        InterfaceUnloaded,
    },
})
export default class C extends Vue {
    @State('factory')
    private factory!: Factory;

    @State('project')
    private project!: Project;

    private toggle: boolean = true;
}

