






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Exporter } from '@/ts/exporter';
import { Factory } from '@/ts/factory';
import { Project } from '@/ts/project';
import { Saver } from '@/ts/saver';
import * as database from '@/ts/utils/database';

import Button from '../Button.vue';
import OverlayFav from './components/OverlayFav.vue';
import OverlayExport from './components/OverlayExport.vue';
import OverlaySelector from './components/OverlaySelector.vue';

@Component({
    components: {
        Button,
        OverlayFav,
        OverlayExport,
        OverlaySelector,
    },
})
export default class C extends Vue {
    @State('factory')
    private factory!: Factory;

    @State('project')
    private project!: Project;

    private selector: boolean = false;

    private exporter: Exporter | null = null;

    private message: string = 'cancel';

    private currentTemplate: any = null;

    private database: any = database;

    private saveAsFavScreen: boolean = false;

    private async exportExcel(template: any): Promise<any> {
        this.selector = false;
        this.saveAsFavScreen = false;
        this.exporter = new Exporter();

        const file: File | undefined = await Saver.databaseToRptz(
            this.factory.database,
            this.factory.order,
            this.project,
            this.factory.name,
            template.name,
            template.content,
        );

        if (file) {
            await this.exporter.start(file, this.factory.name);
            this.message = 'close';
        }
    }

    private close(): void {
        if (this.exporter) {
            this.exporter.cancel();
            this.exporter = null;
        }
    }

    private async upload(files: File[]) {
        console.log('files');
        console.log(files);
        if (files.length > 0) {
            this.currentTemplate = {
                name: files[0].name,
                content: files[0],
            };
            this.saveAsFavScreen = true;
        }
    }
}

