

















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import * as Numeral from 'numeral';

import IconBase from '@/components/IconBase.vue';

import { Point } from '@/ts/point';
import { Project } from '@/ts/project';
import { blend } from '@/ts/utils/blender';

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private point!: Point;

    @Prop()
    private columns!: string[];

    private async togglePoint() {
        if (this.project.reports.current) {
            this.project.reports.current.points.hide(this.point);
            this.project.update();
        }
    }

    private genColor(): string {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return this.point.getColor();
        } else {
            return blend(this.point.getColor(), '#000000', 0.3);
        }
    }
}

