














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import IconBase from '@/components/IconBase.vue';

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue {
    @Prop({ default: 'download' })
    private icon!: string;

    @Prop({ default: '' })
    private text!: string;

    @Prop({ default: '*' })
    private accept!: string;

    private dragging: boolean = false;

    private file: File = new File([], '');

    private drop(event: any) {
        this.dragging = false;
        if (event && event.dataTransfer && event.dataTransfer.files) {
            this.$emit('input', event.dataTransfer.files);
        }
    }
}

