


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { Unit } from '@/ts/unit';

@Component
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private value!: Unit;

    @Prop()
    private name!: string;

    @Prop()
    private min!: number;

    @Prop()
    private max!: number;

    @Prop()
    private step!: number;

    private update(event: any): void {
        this.value.setNumber(event.target.value);
        this.$emit('input');
        this.project.update();
    }
}

