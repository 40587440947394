

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';

import Box from '@/components/Box.vue';

import ButtonAlbum from './components/ButtonAlbum.vue';
import ButtonInformation from './components/ButtonInformation/index.vue';
import ButtonCapture from './components/ButtonCapture.vue';
import ButtonExport from './components/ButtonExport/index.vue';
import ButtonOpen from './components/ButtonOpen.vue';
import ButtonSave from './components/ButtonSave.vue';

@Component({
    components: {
        ButtonCapture,
        ButtonExport,
        ButtonOpen,
        ButtonSave,
        Box,
        ButtonAlbum,
        ButtonInformation,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop({ default: false })
    private startsOpen!: boolean;
}

