import * as Bowser from 'bowser';
import { dictionary, IDICTIONARY } from './dictionary';

export const browser: string = Bowser.getParser(window.navigator.userAgent).getBrowserName();

let d: IDICTIONARY;
if (navigator.language in dictionary) {
    d = (dictionary as any)[navigator.language] as IDICTIONARY;
} else {
    d = dictionary['en-US'];
}

export const DROPDOWN = {
    Materiau: [
        d.materiau1,
        d.materiau2,
        d.materiau3,
        d.materiau4,
    ],
    Couche: [
        d.couche1,
        d.couche2,
        d.couche3,
        d.couche4,
        d.couche5,
        d.couche6,
        d.couche7,
        d.couche8,
        d.couche9,
        d.couche10,
    ],
    Type: [
        d.type1,
        d.type2,
        d.type3,
        d.type4,
        d.type5,
        d.type6,
        d.type7,
        d.type8,
        d.type9,
    ],
    Etat: [
        d.etat1,
        d.etat2,
        d.etat3,
        d.etat4,
    ],
    GTR: [
        d.ns,
        'A1',
        'A2',
        'A3',
        'A4',
        'B1',
        'B2',
        'B3',
        'B4',
        'B5',
        'B6',
        'C1',
        'C2',
        'D1',
        'D2',
        'D3',
    ],
    ClassDeflection: [
        d.ns,
        'D1',
        'D2',
        'D3',
        'D4',
        'D5',
        'D6',
        'D7',
        'D8',
        'D9',
    ],
};
