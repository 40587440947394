import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCompositionApi from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'dragula-with-animation/dist/dragula.min.css';

import './css/main.css';
import { dictionary } from './ts/data/dictionary';

Vue.use(VueI18n);

Vue.use(VueCompositionApi);

const i18n: VueI18n = new VueI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: dictionary as any,
    // silentTranslationWarn: true,
});

console.log('locale:', navigator.language);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
