











































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { Threshold, STATE } from '@/ts/threshold';

import Input from './Input.vue';

@Component({
    components: {
        Input,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    private THRESHOLD_STATE = STATE;

    private threshold: Threshold | null = null;

    private mounted() {
        if (this.project.reports.current) {
            this.threshold = this.project.reports.current.thresholds.current || null;
        }
    }

    private async check(input: string) {
        if (this.threshold) {
            if (
                this.threshold.state === this.THRESHOLD_STATE.DOUBLE
                || (input === 'good' && this.threshold.goodValue.value.toNumber() > this.threshold.badValue.value.toNumber())
            ) {
                this.threshold.badValue.value.setNumber(this.threshold.goodValue.value.toNumber());
            } else if (input === 'bad' && this.threshold.goodValue.value.toNumber() > this.threshold.badValue.value.toNumber()) {
                this.threshold.goodValue.value.setNumber(this.threshold.badValue.value.toNumber());
            }
        }
    }

    private update(): void {
        this.project.update();
    }
}

