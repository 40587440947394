














import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import DragAndDropBase from '@/components/DragAndDropBase.vue';

import { Factory } from '@/ts/factory';
import { Project } from '@/ts/project';

@Component({
    components: {
        DragAndDropBase,
    },
})
export default class C extends Vue {
    @State('factory')
    private factory!: Factory;

    @State('project')
    private project!: Project;

    private async upload(files: File[]) {
        this.$emit('input');
        this.factory.import(files, this.project);
    }

    private getIcon(state: string) {
        switch (state) {
            case 'import.tooManyFiles': return 'alertCircle';
            case 'import.error': return 'alertCircle';
            case 'import.loading': return 'loader';
            default: return 'download';
        }
    }
}

