



















import { Component, Prop, Vue } from 'vue-property-decorator';
import InputBase from '@/components/InputBase.vue';

@Component({
    components: {
        InputBase,
    },
})
export default class C extends Vue {
    @Prop()
    private name!: string;

    @Prop()
    private object!: any;
}

