
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import OverlayBase from '@/components/OverlayBase.vue';

import { Exporter } from '@/ts/exporter';
import { Factory } from '@/ts/factory';
import { Project } from '@/ts/project';
import { Saver } from '@/ts/saver';
import * as database from '@/ts/utils/database';

import Button from '../../Button.vue';

@Component({
    components: {
        Button,
        OverlayBase,
    },
})
export default class C extends Vue {
    @State('factory')
    private factory!: Factory;

    @State('project')
    private project!: Project;

    private favoriteTemplate: any = null;

    private database: any = database;

    private async mounted() {
        this.favoriteTemplate = (await database.getFavoriteTemplate()) || null;
    }
}

