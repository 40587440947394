export interface IDICTIONARY {
    // ---
    // Save traduction
    // ---

    ClassDeflection: string
    Client: string
    Climat: string
    Commentaire: string
    ContactClient: string
    Couche: string
    'D-300': string
    'D-200': string
    D0: string
    D1: string
    D2: string
    D3: string
    D4: string
    D5: string
    D6: string
    D7: string
    D8: string
    D9: string
    D200: string
    D300: string
    D600: string
    D900: string
    D1200: string
    D1500: string
    D1800: string
    D2100: string
    Date: string
    Dossier: string
    Etat: string
    ForceMax: string
    Localite: string
    Materiau: string
    Nom: string
    Numero: string
    Operateur: string
    Ouvrage: string
    PartieOuvrage: string
    PulseTime: string
    Sens: string
    SeuilDeflection: string
    Type: string
    Voie: string

    // ---
    // Vue traduction
    // ---

    add: string
    album: string
    areYouSure: string
    average: string
    cancel: string
    capture: string
    careful: string
    changeIcon: string
    close: string
    colon: string
    couche10: string
    couche1: string
    couche2: string
    couche3: string
    couche4: string
    couche5: string
    couche6: string
    couche7: string
    couche8: string
    couche9: string
    delete: string
    display: string
    displayedValues: string
    etat1: string
    etat2: string
    etat3: string
    etat4: string
    export: string
    'export.init': string
    'export.noConnection': string
    'export.overloading': string
    'export.connectionSecured': string
    'export.sending': string
    'export.somethingWrong': string
    'export.waiting': string
    'export.downloading': string
    'export.clean': string
    'export.done': string
    'export.maybeTooManyImages': string
    exporting: string
    high: string
    import: string
    'import.chooseFile': string
    'import.chooseFileShort': string
    'import.loading': string
    'import.tooManyFiles': string
    'import.error': string
    information: string
    limit: string
    low: string
    materiau1: string
    materiau2: string
    materiau3: string
    materiau4: string
    no: string
    ns: string
    number: string
    open: string
    openMessage: string
    other: string
    platform: string
    project: string
    report: string
    'report.issue': string
    save: string
    threshold: string
    toggleDrag: string
    toggleIcons: string
    toggleLines: string
    toggleText: string
    tryDemo: string
    type1: string
    type2: string
    type3: string
    type4: string
    type5: string
    type6: string
    type7: string
    type8: string
    type9: string
    type: string
    yes: string
    chooseTemplate: string
    next: string
    informations: string
    map: string
    table: string
    file: string
    saveTemplateAsFav: string
    fromOpenedFile: string
    openTemplate: string
    asFavTemplate: string
    style: string
    allRightsReserved: string
}

const DICTIONARY_EN: IDICTIONARY = {
    ClassDeflection: 'Deflection class',
    Client: 'Client',
    Climat: 'Climate',
    Commentaire: 'Comment',
    ContactClient: 'Client contacts',
    Couche: 'Layer',
    Date: 'Date',
    Dossier: 'Folder',
    Etat: 'State',
    ForceMax: 'Force',
    Localite: 'Localization',
    Materiau: 'Material',
    Nom: 'Name',
    Numero: 'Number',
    Operateur: 'Operator',
    Ouvrage: 'Work',
    PartieOuvrage: 'Work part',
    PulseTime: 'Pulse time',
    Sens: 'Direction',
    SeuilDeflection: 'Deflection threshold',
    Type: 'Type',
    Voie: 'Track',
    areYouSure: 'Are you sure that you want to continue ?',
    average: 'Average',
    careful: 'Careful',
    changeIcon: 'Change the icon of the points',
    'import.chooseFile': '<b>Choose a file</b> or drag it here.',
    'import.chooseFileShort': 'Choose a file',
    'import.tooManyFiles': "<b>Error !</b> You're trying to import too many files.",
    'import.loading': '<b>Loading,</b> please wait...',
    'import.error': '<b>Error !</b> Something is wrong with your file.',
    close: 'Close',
    colon: ':',
    couche10: 'Verge',
    couche1: 'Sub base',
    couche2: 'Base',
    couche3: 'Plateform',
    couche4: 'Form background',
    couche5: 'Seat layer',
    couche6: 'Form layer',
    couche7: 'Foundation layer',
    couche8: 'Base layer',
    couche9: 'Tread',
    display: 'Display',
    displayedValues: 'Displayed Values',
    etat1: 'Dried',
    etat2: 'Wet',
    etat3: 'Soggy',
    etat4: 'Frozen',
    export: 'Export',
    high: 'High',
    information: 'Information',
    limit: 'Limit',
    low: 'Low',
    materiau1: 'Silt',
    materiau2: 'Naturel Gravel',
    materiau3: 'Treated gravel',
    materiau4: 'Asphaltic concrete',
    no: 'No',
    ns: 'N.S.',
    number: 'Number',
    open: 'Open',
    openMessage:
        "By opening a new file you will delete everything you have already done and haven't save.",
    other: 'Other',
    platform: 'Platform',
    project: 'Project',
    report: 'Report',
    save: 'Save',
    threshold: 'Threshold',
    toggleDrag: 'Toggle draggable points',
    toggleIcons: "Toggle point's icon",
    toggleLines: 'Toggle the lines',
    toggleText: "Toggle the text inside the point's icon",
    type1: 'Building pavement',
    type2: 'Road',
    type3: 'Building',
    type4: 'Railway',
    type5: 'Hydraulic',
    type6: 'Excavation',
    type7: 'Peripheral excavation',
    type8: 'Trench',
    type9: 'Sanitation',
    type: 'Type',
    yes: 'Yes',
    cancel: 'Cancel',
    'D-300': 'D-300',
    'D-200': 'D-200',
    D0: 'D0',
    D200: 'D200',
    D300: 'D300',
    D600: 'D600',
    D900: 'D900',
    D1200: 'D1200',
    D1500: 'D1500',
    D1800: 'D1800',
    D2100: 'D2100',
    D1: 'D1',
    D2: 'D2',
    D3: 'D3',
    D4: 'D4',
    D5: 'D5',
    D6: 'D6',
    D7: 'D7',
    D8: 'D8',
    D9: 'D9',
    exporting: 'Exporting',
    'export.init': 'Initiating the connection with the server...',
    'export.noConnection': "Couldn't connect with the server.",
    'export.overloading': 'The server is overloading, please try again in a few minutes.',
    'export.connectionSecured': 'Connection secured !',
    'export.sending': 'Sending report to the server...',
    'export.somethingWrong': 'Something went wrong !',
    'export.waiting': 'Waiting for the server to generate the finalized report...',
    'export.downloading': 'Downloading the finalized report...',
    'export.clean': 'Cleaning up...',
    'export.done': 'Done !',
    'export.maybeTooManyImages':
        "Couldn't send data to server, there were most likely too many or too big images",
    import: 'Import',
    capture: 'Capture',
    album: 'Album',
    add: 'Add',
    delete: 'Delete',
    tryDemo: 'or try this demo',
    'report.issue': 'Report an issue',
    chooseTemplate: 'Choose a template',
    next: 'Next',
    informations: 'Informations',
    map: 'Map',
    table: 'Table',
    file: 'File',
    saveTemplateAsFav: 'Save this template as your favorite ?',
    fromOpenedFile: ' from the opened file',
    openTemplate: 'Open a template',
    asFavTemplate: ' as favorite template',
    style: 'Style',
    allRightsReserved: 'All rights reserved',
}

const DICTIONARY_FR: IDICTIONARY = {
    ClassDeflection: 'Classe de déflection',
    Client: 'Client',
    Climat: 'Climat',
    Commentaire: 'Commentaire',
    ContactClient: 'Contact du client',
    Couche: 'Couche',
    Date: 'Date',
    Dossier: 'Dossier',
    Etat: 'Etat',
    ForceMax: 'Force',
    Localite: 'Localité',
    Materiau: 'Matériaux',
    Nom: 'Nom',
    Numero: 'Numéro',
    Operateur: 'Operateur',
    Ouvrage: 'Ouvrage',
    PartieOuvrage: "Partie de l'ouvrage",
    PulseTime: "Temps d'impulsion",
    Sens: 'Sens',
    SeuilDeflection: 'Seuil de déflection',
    Type: 'Type',
    Voie: 'Voie',
    areYouSure: 'Etes-vous sur de vouloir continuer ?',
    average: 'Moyenne',
    careful: 'Attention',
    changeIcon: "Changer l'icone des points sur la carte",
    'import.chooseFile': '<b>Choisissez un fichier</b> ou glisser le ici.',
    'import.chooseFileShort': 'Choisissez un fichier',
    'import.tooManyFiles': '<b>Erreur !</b> Vous avez sélectionnez trop de fichiers.',
    'import.loading': '<b>Chargement...</b>',
    'import.error': '<b>Erreur !</b> Quelque chose ne va pas dans votre fichier.',
    close: 'Fermer',
    colon: `${String.fromCharCode(160)}:`,
    couche10: 'Accotement',
    couche1: 'Arase',
    couche2: 'Terrassement',
    couche3: 'Plateforme',
    couche4: 'Fond de forme',
    couche5: "Couche d'assise",
    couche6: 'Couche de forme',
    couche7: 'Couche de fondation',
    couche8: 'Couche de base',
    couche9: 'Couche de roulement',
    display: 'Affichage',
    displayedValues: 'Valeurs affichées',
    etat1: 'Sèche',
    etat2: 'Humide',
    etat3: 'Detrempée',
    etat4: 'Gelée',
    export: 'Export',
    high: 'Haut',
    information: 'Informations',
    limit: 'Limite',
    low: 'Bas',
    materiau1: 'Limon',
    materiau2: 'Grave naturelle',
    materiau3: 'Grave traitée',
    materiau4: 'Béton bitumineux',
    no: 'Non',
    ns: 'N.S.',
    number: 'Nombre',
    open: 'Ouvrir',
    openMessage:
        "En ouvrant un nouveau fichier vous allez supprimer tout ce qui avait fait et qui n'a pas été sauvegardé.",
    other: 'Autre',
    platform: 'Platforme',
    project: 'Projet',
    report: 'Rapport',
    save: 'Sauvegarder',
    threshold: 'Seuil',
    toggleDrag: 'Activer ou désactiver le déplacement des points',
    toggleIcons: 'Afficher ou cacher les points sur la carte',
    toggleLines: 'Afficher ou cacher les lignes sur la carte',
    toggleText: 'Afficher ou cacher le texte des points sur la carte',
    type1: 'Dallage bâtiment',
    type2: 'Voirie',
    type3: 'Bâtiment',
    type4: 'Ferroviaire',
    type5: 'Hydraulique',
    type6: 'Fouille',
    type7: 'Fouille périphérique',
    type8: 'Tranchée',
    type9: 'Assainissement',
    type: 'type',
    yes: 'Oui',
    cancel: 'Annuler',
    'D-300': 'D-300',
    'D-200': 'D-200',
    D0: 'D0',
    D200: 'D200',
    D300: 'D300',
    D600: 'D600',
    D900: 'D900',
    D1200: 'D1200',
    D1500: 'D1500',
    D1800: 'D1800',
    D2100: 'D2100',
    D1: 'D1',
    D2: 'D2',
    D3: 'D3',
    D4: 'D4',
    D5: 'D5',
    D6: 'D6',
    D7: 'D7',
    D8: 'D8',
    D9: 'D9',
    exporting: 'Export en cours',
    'export.init': 'Initialisation de la connexion avec le serveur...',
    'export.noConnection': 'Impossible de se connecter au serveur.',
    'export.overloading':
        'Le serveur reçoit trop de demande actuellement, merci de réessayer dans quelques minutes.',
    'export.connectionSecured': 'Connexion avec le serveur réussi !',
    'export.sending': 'Envoie du rapport au serveur en cours...',
    'export.somethingWrong': 'Il y a malheureusement eu un problème.',
    'export.waiting': "En attente d'une réponse du serveur...",
    'export.downloading': 'Téléchargement du rapport en cours...',
    'export.clean': 'Nettoyage en cours...',
    'export.done': 'Export réussi !',
    'export.maybeTooManyImages':
        "Erreur lors de l'envoie du rapport au serveur, il y avait possiblement trop d'images ou elles étaient trop lourdes",
    import: 'Import',
    capture: 'Capture',
    album: 'Album',
    add: 'Ajouter',
    delete: 'Supprimer',
    tryDemo: 'ou essayez cette demo',
    'report.issue': 'Signaler un problème',
    chooseTemplate: 'Choix du modèle',
    next: 'Suivant',
    informations: 'Informations',
    map: 'Carte',
    table: 'Tableau',
    file: 'Fichier',
    saveTemplateAsFav: 'Sauvegarder ce modèle comme favori ?',
    fromOpenedFile: ' du fichier ouvert',
    openTemplate: 'Ajouter un modèle',
    asFavTemplate: ' en tant que modèle favori',
    style: 'Style',
    allRightsReserved: 'Tous droits réservés',
}

export const dictionary = {
    en: DICTIONARY_EN,
    'en-US': DICTIONARY_EN,
    fr: DICTIONARY_FR,
    'fr-FR': DICTIONARY_FR,
}
