





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';

@Component
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private columns!: string[];

    @Prop()
    private columnsHistory!: string[];

    private list: String[] = [];

    private mounted() {
        if (this.project.reports.current) {
            this.list = [...this.project.reports.current.values.list].sort(this.project.reports.current.sortValues);
        }
    }

    private refreshColumns(value: string): void {
        if (!this.columns.includes(value)) {
            const index = this.columns.indexOf(this.columnsHistory[2], 0);
            if (index > -1) {
                this.columns.splice(index, 1);
            }
            this.columns.push(value);

            this.columns.sort((this.project.reports.current as any).sortValues);

            this.columnsHistory.splice(2, 1);
            this.columnsHistory.splice(0, 0, value);
        }
    }
}

