




















































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { STATE, Threshold } from '@/ts/threshold';

import BoxInput from './components/BoxInput.vue';

@Component({
    components: {
        BoxInput,
    },
})
export default class VueComponent extends Vue {
    @State('project')
    private project!: Project;

    private THRESHOLD_STATE = STATE;

    private threshold: Threshold | null = null;

    private mounted() {
        if (
            this.project
            && this.project.reports
            && this.project.reports.current
            && this.project.reports.current.thresholds
            && this.project.reports.current.thresholds.current
        ) {
            this.threshold = this.project.reports.current.thresholds.current;
        }
    }

    private gradient(): string {
        if (this.threshold) {
            return `linear-gradient(90deg,${this.threshold.goodColor},${this.threshold.badColor})`;
        }
        return '#ffffff';
    }
}

