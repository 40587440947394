import Vue from 'vue';
import Vuex from 'vuex';

import { Project } from '../ts/project';
import { Factory } from '../ts/factory';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        factory: new Factory(),
        project: new Project(),
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
});
