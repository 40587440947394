



















































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import DropdownBase from '@/components/DropdownBase.vue';
import OverlayBase from '@/components/OverlayBase.vue';

import { Project } from '@/ts/project';

import Column from './components/Column.vue';

@Component({
    components: {
        Column,
        DropdownBase,
        OverlayBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    private opening: boolean = false;
}

