import * as JSZip from 'jszip'
import * as _ from 'lodash'

import { Point } from './point'
import { Project } from './project'
import { Threshold } from './threshold'
import * as db from './utils/database'

export class Saver {
    public static async databaseToRptz(
        database: any,
        order: object[],
        project: Project,
        filename: string,
        templateName?: string,
        templateBlob?: any
    ): Promise<any> {
        const databaseCopy: any = _.cloneDeep(database)

        this.processDatabase(databaseCopy, project)

        const data: string = this.databaseToString(databaseCopy, order)

        return this.toZip(data, project, filename, templateName, templateBlob)
    }

    private static processDatabase(database: any, project: Project): void {
        let points: Point[] = []

        if (project.reports.current) {
            points = project.reports.current.points.children
        }

        const nbDropPerPoint: number = database.Drops.length / database.ReportPoints.length

        const threshold: Threshold | any = (project.reports.current as any).thresholds.current
        if (threshold) {
            database.Plateformes.SeuilDeflection.value.setNumber(
                threshold.goodValue.value.toNumber()
            )
            database.Plateformes.ClassDeflection.value.current = threshold.name

            if (threshold.name === 'other') {
                database.Plateformes.SeuilDeflectionMax = threshold.badValue
            }
        }

        const reportPointsCopy: any = _.cloneDeep(database.ReportPoints)
        const dropsCopy: any = _.cloneDeep(database.Drops)
        console.log(dropsCopy)
        database.ReportPoints = []
        database.Drops = []
        points.forEach((point: Point) => {
            if (!point.isHidden()) {
                const index: number = point.originalNumber - 1

                database.ReportPoints.push(reportPointsCopy[index])
                console.log(index, nbDropPerPoint)
                for (let i = 0; i < nbDropPerPoint; i++) {
                    database.Drops.push(dropsCopy[index * nbDropPerPoint + i])
                }
            }
        })

        Object.keys(database.ArrayResult).forEach((key: string) => {
            database.ArrayResult[key] = []
        })

        console.log(database.Drops)
        database.ReportPoints.forEach((information: any) => {
            const index = database.ReportPoints.indexOf(information)

            const drop: any = database.Drops[index * nbDropPerPoint + nbDropPerPoint - 1]
            console.log(drop)

            Object.keys(database.ArrayResult).forEach((key: string) => {
                const object: any =
                    information[key] ||
                    drop[key] ||
                    (key.toLowerCase() === 'forcemax' ? drop.ForceMax : undefined)

                if (object) {
                    database.ArrayResult[key].push(object)
                }
            })
        })
    }

    private static databaseToString(database: any, order: object[]): string {
        let data: string = ''

        order.forEach((element: any) => {
            let object: any = database[element.name]
            if (Array.isArray(object)) {
                ;[object] = object.splice(0, 1)
            }

            if (object) {
                data += `TABLE ${element.name}\n`

                element.content.forEach((val: string) => {
                    data += `${val}\t`

                    const REGEX: RegExp = /^D_?[0-9]+$/
                    if (REGEX.test(val)) {
                        val = val.replace('_', '-')

                        const REGEX2: RegExp = /^D-?0[0-9]+$/
                        while (REGEX2.test(val)) {
                            val = val.replace(/(?!^D-?)0/, '')
                        }
                    }

                    const value: any = object[val]

                    if (Array.isArray(value)) {
                        value.forEach((v: any) => {
                            data += this.objectToString(v)
                            data += value.indexOf(v) === value.length - 1 ? '' : '\t'
                        })
                    } else {
                        data += this.objectToString(value)
                    }
                    data += '\n'
                })
            }
        })

        return data
    }

    private static objectToString(value: any): string {
        if (value.kind === 'unit') {
            return value.value.toDisplayedNumber('fr').replace(/\s/g, '')
        }
        if (value.kind === 'date') {
            return value.oldValue
        } else if (value.kind === 'dropdown') {
            return value.value.current
        } else if (value.kind === 'dropdownFixed') {
            return value.value.current
        } else if (value.kind === 'boolean') {
            let str: string = String(value.value)
            str = str.charAt(0).toUpperCase() + str.slice(1)
            return str
        } else if (value.kind) {
            return value.value
        } else {
            return value
        }
    }

    private static async toZip(
        data: string,
        project: Project,
        name: string,
        templateName?: string,
        templateBlob?: any
    ): Promise<File | undefined> {
        let file: File | undefined

        const zip = new JSZip()
        zip.file('save.txt', data)

        if (templateName && templateBlob) {
            zip.file(templateName, templateBlob)
        }

        if (project.reports.current) {
            const maps = zip.folder('Maps')
            let i = 0
            // console.log(project.reports.current.screenshots)
            project.reports.current.screenshots.forEach((screenshot: string) => {
                i++
                maps.file(
                    `${i}.png`,
                    screenshot.replace(/data:image\/(png|jpg|jpeg);base64,/, ''),
                    {
                        base64: true,
                    }
                )
            })
        }

        const content: any = await zip.generateAsync({
            type: 'blob',
        })

        file = new File([content], `${name}.rptz`)

        return file
    }
}
