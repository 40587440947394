























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import IconBase from '@/components/IconBase.vue';
import Header from '@/components/Header.vue';
import BoxMap from './components/BoxMap/index.vue';
import BoxThreshold from './components/BoxThreshold/index.vue';
import BoxTable from './components/BoxTable/index.vue';
import BoxFile from './components/BoxFile/index.vue';
import Footer from './components/Footer.vue';

@Component({
    components: {
        Header,
        BoxMap,
        BoxThreshold,
        BoxTable,
        BoxFile,
        IconBase,
        Footer,
    },
})
export default class C extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION || '1.0';

    private visibleTabs = [true, true, true, true, true];

    public windowWidth: number = window.innerWidth;

    public mounted() {
        window.addEventListener('resize', this.handleResize);
    }

    public handleResize() {
        this.windowWidth = window.innerWidth;
    }

    public beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }

    private toggleTabs(toggle: boolean, number: number) {
        if (! toggle) {
            this.visibleTabs = [true, true, true, true, true];
        } else {
            this.visibleTabs = [false, false, false, false, false];
            this.visibleTabs[number] = true;
        }
    }
}

