



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Icon } from '@/ts/icon';

@Component
export default class IconBase extends Vue {
    @Prop()
    private fill!: string;

    @Prop()
    private name!: string;

    @Prop({ default: 24 })
    private size!: number;

    @Prop()
    private stroke!: string;

    @Prop()
    private strokeWidth!: number;

    @Prop({ default: '' })
    private text!: string;

    private icon: Icon = new Icon();
}

