











import { Component, Vue, Prop } from 'vue-property-decorator';

import Box from '@/components/Box.vue';

import Map from './components/Map.vue';
import Display from './components/Display.vue';
import Values from './components/Values.vue';

@Component({
    components: {
        Box,
        Map,
        Display,
        Values,
    },
})
export default class C extends Vue {
    @Prop({ default: false })
    private startsOpen!: boolean;
}

