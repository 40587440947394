import * as Mapbox from 'mapbox-gl'
import { styles } from './data/enums'
import { Icon } from './icon'

export class Mapview {
  public _map: Mapbox.Map | undefined

  public _style: string | undefined

  constructor() {
    ;(Mapbox as any).accessToken =
      'pk.eyJ1Ijoia2dhd2xpayIsImEiOiJjbGRuYXEzcmMwaGllM290Nnd2N3RjdjQxIn0.DhGgwyQRRrR9l1KDe9MZBQ'
  }

  public init(style: styles): void {
    this._map = new Mapbox.Map({
      container: 'map',
      preserveDrawingBuffer: true,
      style
    }).addControl(new Mapbox.NavigationControl(), 'top-left')

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        p => {
          if (this._map && p.coords) {
            const marker = new Mapbox.Marker({
              element: (new Icon()
                .setIcon('mapPin')
                .setStrokeWidth(2)
                .setFill('#fc8181')
                .genColoredStroke().content as unknown) as HTMLElement
            })
              .setLngLat({
                lon: p.coords.longitude,
                lat: p.coords.latitude
              })
              .addTo(this._map)

            navigator.geolocation.watchPosition(position => {
              const latitude = position.coords.latitude
              const longitude = position.coords.longitude
              marker.setLngLat({
                lon: longitude,
                lat: latitude
              })
            })
          }
        },
        erro => {
          console.log(erro)
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      )
    }

    this._map.doubleClickZoom.disable()
  }

  public setStyle(style: string): void {
    if (this._map) {
      this._style = style
      this._map.setStyle((styles as any)[style])
    }
  }
}
