import axios from 'axios'

const address: string = 'https://api.rincent-ndt.fr/api/Reports'

const logAndReturn = (response: any) => {
    if (response.data) {
        console.log(response.data)
    } else {
        console.error(response)
    }

    return response.data
}

export const del = async (path: string, data?: any): Promise<any> => {
    const result = await axios
        .delete(address + path, { params: data })
        .then(logAndReturn)
        .catch((error) => {
            console.log(error)
        })
    return result
}

export const get = async (path: string, data?: any, header?: any): Promise<any> => {
    const result = await axios
        .get(address + path, { params: data, ...header })
        .then(logAndReturn)
        .catch((error) => {
            console.log(error)
        })
    return result
}

export const post = async (path: string, file: File): Promise<any> => {
    const form = new FormData()
    form.set('file', file)
    const result = await axios
        .post(address + path, form, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                maxContentLength: 'Infinity',
                maxBodyLength: 'Infinity',
            },
        })
        .then(logAndReturn)
        .catch((error) => {
            console.log(error)
        })
    return result
}
