





































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import DropdownBase from './DropdownBase.vue';

@Component({
    components: {
        DropdownBase,
    },
})
export default class CustomInput extends Vue {
    @Prop()
    private inputKey!: string;

    @Prop()
    private inputValue!: any;

    private language: string = navigator.language;
}

