






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import DropdownBase from '@/components/DropdownBase.vue';

import { Project } from '@/ts/project';

@Component({
    components: {
        DropdownBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    private values: CurrentList<string> | null = null;

    private mounted() {
        if (
            this.project
            && this.project.reports
            && this.project.reports.current
        ) {
            this.values = this.project.reports.current.values;
        }
    }

    private updateCurrentValue(name: string): void {
        if (this.values) {
            this.values.current = name;
            this.project.update();
        }
    }

    private clean(list: string[]): string[] {
        return [...list].filter(value => value !== 'Force' && value !== 'ForceMax');
    }
}

