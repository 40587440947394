import * as idb from 'idb';

const getDatabase = async () => {
    const database = await idb.openDB('mapview', 1, {
        upgrade(db) {
            db.createObjectStore('favoriteTemplate');
        },
    });
    return database;
};

// export const getTemplatesNames = async () => {
//     const db = await getDatabase();
//     return db.getAllKeys('templates');
// };

export const getFavoriteTemplate = async () => {
    const db = await getDatabase();
    const key = (await db.getAllKeys('favoriteTemplate'))[0];
    if (key) {
        const content = await db.get('favoriteTemplate', key);
        return {
            name: key,
            content,
        };
    } else {
        return undefined;
    }
};

export const saveFavoriteTemplate = async (template: any) => {
    const db = await getDatabase();
    await db.clear('favoriteTemplate');
    await db.put('favoriteTemplate', template.content, template.name);
};
