






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { styles } from '@/ts/data/enums';

@Component
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private mapStyle!: styles;

    private click(): void {
        if (this.project._mapview._style !== this.mapStyle) {
            if (this.mapStyle === styles.dark) {
                document.documentElement.setAttribute('data-theme', 'dark');
            } else {
                document.documentElement.setAttribute('data-theme', 'light');
            }
            this.project._mapview.setStyle(this.mapStyle);
        }
    }
}

