import alertCircle from './alert-circle.svg'
import alertTriangle from './alert-triangle.svg'
import arrowRight from './arrow-right.svg'
import award from './award.svg'
import bell from './bell.svg'
import bookmark from './bookmark.svg'
import camera from './camera.svg'
import check from './check.svg'
import chevronDown from './chevron-down.svg'
import chevronUp from './chevron-up.svg'
import circleLocked from './circle-locked.svg'
import circleMovable from './circle-movable.svg'
import circleOff from './circle-off.svg'
import circle from './circle.svg'
import download from './download.svg'
import droplet from './droplet.svg'
import eyeOff from './eye-off.svg'
import eye from './eye.svg'
import filePlus from './file-plus.svg'
import flag from './flag.svg'
import grab from './grab.svg'
import grid from './grid.svg'
import helpCircle from './help-circle.svg'
import hexagon from './hexagon.svg'
import imageOff from './image-off.svg'
import image from './image.svg'
import loader from './loader.svg'
import mapPin from './map-pin.svg'
import menu from './menu.svg'
import moreVertical from './more-vertical.svg'
import move from './move.svg'
import number from './number.svg'
import numberOff from './number-off.svg'
import number2 from './number2.svg'
import number2Off from './number2-off.svg'
import puff from './puff.svg'
import octagon from './octagon.svg'
import share from './share-3.svg'
import shareOff from './share-3-off.svg'
import square from './square.svg'
import star from './star.svg'
import trash2 from './trash-2.svg'
import triangle from './triangle.svg'
import typeOff from './type-off.svg'
import type from './type.svg'
import x from './x.svg'

const ICONS: any = {
    alertCircle,
    alertTriangle,
    arrowRight,
    award,
    bell,
    bookmark,
    camera,
    check,
    chevronDown,
    chevronUp,
    circleOff,
    circleLocked,
    circleMovable,
    circle,
    download,
    droplet,
    eyeOff,
    eye,
    filePlus,
    flag,
    grab,
    grid,
    helpCircle,
    hexagon,
    imageOff,
    image,
    loader,
    mapPin,
    menu,
    moreVertical,
    move,
    number,
    numberOff,
    number2,
    number2Off,
    puff,
    octagon,
    share,
    shareOff,
    square,
    star,
    trash2,
    triangle,
    typeOff,
    type,
    x,
}

Object.entries(ICONS).forEach(([key, value]) => {
    ICONS[key] = ICONS[key].replace('data:image/svg+xml,', '')
    ICONS[key] = ICONS[key].replace(/%3E/gi, '>')
    ICONS[key] = ICONS[key].replace(/%3C/gi, '<')
})

export { ICONS }
