












import {
    defineComponent,
    onMounted,
    ref,
} from '@vue/composition-api';

import { Project } from '@/ts/project';
import { styles } from '@/ts/data/enums';

export default defineComponent({
    setup(props, context) {
        const { $store } = context.root;
        const project: Project = $store.state.project;

        onMounted(() => {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                project.init(styles.dark);
                project._mapview._style = 'dark';
            } else {
                project.init(styles.streets);
                project._mapview._style = 'streets';
            }
        });
    },
});

