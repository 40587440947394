import * as Mapbox from 'mapbox-gl'
import { Icon } from './icon'
import { Threshold } from './threshold'
import router from '../router'

export class Point {
    public drops: object[]

    public information: any

    public values: any

    public _marker: Mapbox.Marker = new Mapbox.Marker()

    public _hidden: boolean = false

    public originalNumber: number = 0

    private _hiddenText: boolean = false

    private _icon: Icon = new Icon()

    constructor(information: object, drops: object[]) {
        this.drops = drops
        this.information = information
        this._marker = new Mapbox.Marker({
            element: (this._icon.content as unknown) as HTMLElement,
        })
            .setLngLat(
                new Mapbox.LngLat(
                    this.information.Longitude.value.toNumber(),
                    this.information.Latitude.value.toNumber()
                )
            )
            .on('dragend', () => {
                this.information.Longitude.value.setNumber(this._marker.getLngLat().lng)
                this.information.Latitude.value.setNumber(this._marker.getLngLat().lat)
            })
    }

    public clean(): void {
        this._marker.remove()
    }

    public hide(parentHidden: boolean): void {
        this._hidden = !this._hidden
        if (!parentHidden) {
            this._icon.toggle()
        }
    }

    public isHidden(): boolean {
        return this._hidden
    }

    public toggle(): void {
        if (!this._hidden) {
            this._icon.toggle()
        }
    }

    public toggleNumber(hideText: boolean): void {
        this._icon.setText(this.information.Numero.value)

        if (this._hiddenText !== hideText) {
            this._icon.toggleText()
            this._hiddenText = !this._hiddenText
        }

        if (this._hiddenText) {
            this._icon.setSize(18)
        } else {
            this._icon.setFontSize(12)
            this._icon.setSize(24)
        }
    }

    public toggleValue(hideText: boolean, name: string): void {
        this._icon.setText(
            this.values[name].kind === 'unit'
                ? Math.trunc(this.values[name].value.toNumber())
                : this.values[name].value
        )

        if (this._hiddenText !== hideText) {
            this._icon.toggleText()
            this._hiddenText = !this._hiddenText
        }

        if (this._hiddenText) {
            this._icon.setSize(18)
        } else {
            this._icon.setFontSize(8)
            this._icon.setSize(28)
        }
    }

    public update(threshold: Threshold, id: string, icon: string, groupHidden: boolean): void {
        if (!this._hidden && groupHidden && !this._icon.isHidden()) {
            this._icon.toggle()
        } else if (!this._hidden && !groupHidden && this._icon.isHidden()) {
            this._icon.toggle()
        }

        this._icon
            .setIcon(icon)
            .setStrokeWidth(2)
            .setFill(
                threshold.getColor(
                    this.values[id].kind === 'number'
                        ? this.values[id].value
                        : this.values[id].value.toNumber()
                )
            )
            .genColoredStroke()

        if (this._icon.getText() && this._icon.getText() != this.information.Numero.value) {
            this._icon.setText(
                this.values[id].kind === 'unit'
                    ? Math.trunc(this.values[id].value.toNumber())
                    : this.values[id].value
            )
        } else {
            this._icon.setText(this.information.Numero.value)
        }

        if (!this._hiddenText) {
            if (this._icon.getText() != this.information.Numero.value) {
                this._icon.setFontSize(8)
                this._icon.setSize(28)
            } else {
                this._icon.setFontSize(12)
                this._icon.setSize(24)
            }
        }

        let html: string = ''
        Object.entries(this.values).forEach(([key, value]: any) => {
            if (key && key !== 'NumeroReportPoints' && key !== 'Numero') {
                html += `<b>${router.app.$t(key)}${router.app.$t('colon')}</b> `
                switch (value.kind) {
                    case 'unit':
                        html += value.value.toString()
                        break
                    default:
                        html += value.value
                        break
                }
                html += '<br>'
            }
        })

        const POP = new Mapbox.Popup({ offset: 20 }).setHTML(html)
        this._marker.setPopup(POP)
    }

    public getNumber(): number {
        return this.information.Numero.value
    }

    public setNumber(number: number): void {
        if (this._icon.getText() == this.information.Numero.value) {
            this._icon.setText(String(number))
        }
        this.information.Numero.value = number
    }

    public setOriginalNumber(number: number): void {
        this.originalNumber = number
        this.information.Numero.value = number
    }

    public getColor(): string {
        return this._icon.color
    }
}
