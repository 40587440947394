


































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class C extends Vue {
    @Prop()
    private title!: string;

    @Prop({ default: false })
    private full!: boolean;

    private mounted() {
        document.body.appendChild(this.$el);
    }

    private beforeDestroy() {
        if (document.body.contains(this.$el)) {
            document.body.removeChild(this.$el);
        }
    }
}

