



























import { Component, Prop, Vue } from 'vue-property-decorator';

import { blend } from '@/ts/utils/blender';

@Component
export default class C extends Vue {
    @Prop()
    private color!: string;

    private genColorButtonStyle(element: any, percentage?: number) {
        if (percentage === undefined) {
            element.style.backgroundColor = this.color;
            element.style.borderColor = blend(this.color, '#000000', 0.3);
        } else {
            element.style.backgroundColor = blend(this.color, '#000000', percentage);
            element.style.borderColor = blend(this.color, '#000000', percentage + 0.3);
        }
    }

    private blend(color1: string, color2: string) {
        return blend(color1, color2, 0.3);
    }
}

