import { Mapview } from './mapview';
import { ReportBase } from './reportBase';
import { ReportHeavydyn } from './reportHeavydyn';
import { styles } from './data/enums';

export class Project {
    public _mapview: Mapview;

    public information: any = undefined;

    public informationPlatform: any = undefined;

    public currentTemplate: any;

    public reports: CurrentList<ReportHeavydyn> = {
        current: undefined,
        list: [],
    };

    constructor() {
        this._mapview = new Mapview();
    }

    public clean(): void {
        this.information = undefined;
        this.informationPlatform = undefined;
        this.reports.current = undefined;
        this.reports.list.forEach((report: ReportBase) => {
            if (this._mapview._map) {
                report.clean(this._mapview._map);
            }
        });
        this.reports.list = [];
    }

    public init(style: styles): void {
        this._mapview.init(style);
        if (this._mapview._map) {
            this._mapview._map.on('style.load', () => {
                if (this.reports.current
                    && this._mapview._map
                    && ! this.reports.current.line.hidden
                ) {
                    this.reports.current.line.addTo(this._mapview._map);
                }
            });
        }
    }

    public update(): void {
        if (this._mapview._map && this.reports.current) {
            this.reports.current.update(this._mapview._map);
        }
    }

    public toggleLine(): void {
        if (this._mapview._map && this.reports.current) {
            this.reports.current.line.toggle(this._mapview._map);
        }
    }
}
