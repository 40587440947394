export const enum colors {
    black = '#1a202c', // gray-900
    blue = '#63b3ed', // blue-400
    darkGrey = '#4a5568', // gray-700
    green = '#68d391', // green-400
    grey = '#cbd5e0', // gray-400
    orange = '#f6ad55', // orange-400
    purple = '#b794f4', // purple-400
    red = '#fc8181', // red-400
    white = '#f7fafc', // grey-100
    yellow = '#f6e05e', // yellow-400
}

export enum styles {
    streets = 'mapbox://styles/mapbox/streets-v10',
    light = 'mapbox://styles/mapbox/light-v9',
    dark = 'mapbox://styles/mapbox/dark-v9',
    satellite = 'mapbox://styles/mapbox/satellite-streets-v10',
}
