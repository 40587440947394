






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import IconBase from '@/components/IconBase.vue';

import { Project } from '@/ts/project';
import { ReportBase } from '@/ts/reportBase';

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue {
    @Prop()
    private active!: boolean;

    @Prop()
    private title!: string;

    @Prop()
    private icon!: string;
}

