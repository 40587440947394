

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { Project } from '@/ts/project'
import { ReportBase } from '@/ts/reportBase'

import IconBase from '@/components/IconBase.vue'
import Button from './Button.vue'

@Component({
    components: {
        Button,
        IconBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project

    private readonly ICONS = [
        'bookmark',
        'circle',
        'droplet',
        'hexagon',
        'octagon',
        'square',
        'star',
        'triangle',
    ]

    private showIcons: boolean = false

    private report: ReportBase | null = null

    private mounted() {
        if (this.project && this.project.reports && this.project.reports.current) {
            this.report = this.project.reports.current as ReportBase
        }
    }
}
