import { saveAs } from 'file-saver'
import { del, get, post } from './utils/rest'

enum CodeServer {
    Ok = 1,
    QueueFull = 2,
    TokenNotFound = 3,
    SlotInProcessing = 4,
    ReportNotReady = 5,
    InternalError = 6,
    ReportAlreadyHaveData = 7,
}

enum CodeClient {
    Initiated = -1,
    WaitingData = 0,
    WaitingToBeProcessed = 1,
    InProcessing = 2,
    ScheduledForDeletion = 3,
    ProcessedOk = 4,
    ProcessedKo = 5,
}

enum Message {
    noConnection = 'export.noConnection',
    // overloading = 'export.overloading',
    // connectionSecured = 'export.connectionSecured',
    // sending = 'export.sending',
    somethingWrong = 'export.somethingWrong',
    // waiting = 'export.waiting',
    maybeTooManyImages = 'export.maybeTooManyImages',
    downloading = 'export.downloading',
    clean = 'export.clean',
    done = 'export.done',
}

interface IStatus {
    message: string
    code?: CodeClient
    error?: string
    position?: number
    canceled?: boolean
    token?: number
}

export class Exporter {
    public status: IStatus = {
        message: 'export.init',
    }

    public async start(file: File, name: string): Promise<void> {
        let result: any = await get(`/GetToken?local=${navigator.language}`)
        let data: ArrayBuffer

        this.status.message = result.OperationMessage

        if (!result || this.status.canceled) {
            this.status.message = Message.noConnection
            return
        } else if (result.OperationStatusCode === CodeServer.QueueFull) {
            return
        }

        this.status.token = result.Token

        result = await post(`/PostReportData?token=${this.status.token}`, file)

        if (!result || this.status.canceled) {
            console.log('here', result)
            this.status.message = Message.maybeTooManyImages
            return
        }

        this.status.message = result.OperationMessage
        console.log('here2', result)

        let ellipsis: string = '.'
        do {
            // eslint-disable-next-line no-await-in-loop
            result = await get('/GetStatus', { token: this.status.token })

            this.status.message =
                result.SlotStatusMessage + ellipsis + ' '.repeat(3 - ellipsis.length)

            ellipsis += '.'
            ellipsis = ellipsis === '....' ? '.' : ellipsis

            this.status.code = result.SlotStatusCode || CodeClient.ProcessedKo

            // eslint-disable-next-line no-await-in-loop
            await new Promise((r) => setTimeout(r, 250))
        } while (
            !this.status.canceled &&
            this.status.code !== CodeClient.ProcessedOk &&
            this.status.code !== CodeClient.ProcessedKo
        )

        if (!this.status.canceled && this.status.code === CodeClient.ProcessedOk) {
            this.status.message = Message.downloading
            data = await get(
                '/GetReport',
                { token: this.status.token },
                {
                    responseType: 'arraybuffer',
                }
            )
        } else {
            this.status.message = result.ProcessingErrorMessage
            return
        }

        if (data && !this.status.canceled) {
            this.status.message = Message.clean
            await del('/DeleteToken', { token: this.status.token })

            if (!this.status.canceled) {
                this.status.message = Message.done
                saveAs(
                    new Blob([new Uint8Array(data).buffer], { type: 'application/octet-stream' }),
                    `${name}.xlsx`
                )
            }
        } else {
            this.status.message = Message.somethingWrong
        }
    }

    public cancel(): void {
        this.status.canceled = true
        if (this.status.token) {
            del('/DeleteToken', { token: this.status.token })
        }
    }
}
