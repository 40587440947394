




































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import IconBase from '@/components/IconBase.vue';

@Component({
    components: {
        IconBase,
    },
})
export default class C extends Vue { }

