






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { Threshold } from '@/ts/threshold';

import DropdownBase from '@/components/DropdownBase.vue';
import IconBase from '@/components/IconBase.vue';
import Box from '@/components/Box.vue';

import BoxThresholdOther from './components/BoxThresholdOther/index.vue';
import ValuesColor from './components/ValuesColor.vue';

@Component({
    components: {
        Box,
        BoxThresholdOther,
        DropdownBase,
        IconBase,
        ValuesColor,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop({ default: false })
    private startsOpen!: boolean;

    private thresholds: CurrentList<Threshold> | null = null;

    private mounted() {
        if (
            this.project
            && this.project.reports
            && this.project.reports.current
            && this.project.reports.current.thresholds
        ) {
            this.thresholds = this.project.reports.current.thresholds;
        }
    }

    private updateCurrentThreshold(name: string): void {
        if (this.thresholds) {
            this.thresholds.list.forEach((threshold: Threshold) => {
                if (this.thresholds && this.$t(threshold.name) === name) {
                    this.thresholds.current = threshold;

                    if (name !== this.$t('other')) {
                        this.thresholds.list[this.thresholds.list.length - 1].goodValue.value.setNumber(threshold.goodValue.value.toNumber());
                        this.thresholds.list[this.thresholds.list.length - 1].badValue.value.setNumber(threshold.goodValue.value.toNumber());
                    }
                    this.project.update();
                }
            });
        }
    }
}

