










import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import IconBase from '@/components/IconBase.vue'

import { Project } from '@/ts/project'

import OverlayAlbum from './OverlayAlbum.vue'

@Component({
    components: {
        IconBase,
        OverlayAlbum,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project

    private show: boolean = false

    private remove(key: number): void {
        if (this.project.reports.current) {
            this.project.reports.current.screenshots.splice(key, 1)
            if (this.project.reports.current.screenshots.length === 0) {
                this.show = false
            }
        }
    }
}
