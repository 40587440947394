import * as Mapbox from 'mapbox-gl'
import { ReportBase } from './reportBase'
import { Unit } from './unit'
import { Threshold } from './threshold'

export class ReportHeavydyn extends ReportBase {
    public distance: string

    public force: string

    public thresholds: CurrentList<Threshold> = {
        current: undefined,
        list: [],
    }

    public values: CurrentList<string> = {
        current: undefined,
        list: [],
    }

    constructor(map: Mapbox.Map, distance: string, force: string) {
        super(map)
        this.distance = distance
        this.force = force

        this.thresholds.list.push(new Threshold('ns', this.distance, 0))
        this.thresholds.list.push(new Threshold('D1', this.distance, 20))
        this.thresholds.list.push(new Threshold('D2', this.distance, 30))
        this.thresholds.list.push(new Threshold('D3', this.distance, 45))
        this.thresholds.list.push(new Threshold('D4', this.distance, 75))
        this.thresholds.list.push(new Threshold('D5', this.distance, 100))
        this.thresholds.list.push(new Threshold('D6', this.distance, 150))
        this.thresholds.list.push(new Threshold('D7', this.distance, 200))
        this.thresholds.list.push(new Threshold('D8', this.distance, 300))
        this.thresholds.list.push(new Threshold('D9', this.distance, 1000))
        this.thresholds.list.push(new Threshold('other', this.distance, 0, 0, 0, 3000))
        ;[this.thresholds.current] = this.thresholds.list
    }

    public getAverage(): number {
        if (this.values.current) {
            let average: number = 0

            // eslint-disable-next-line no-restricted-syntax
            for (const POINT of this.points.children) {
                if (!POINT.isHidden() && POINT.values[this.values.current]) {
                    average += POINT.values[this.values.current].value.toNumber()
                }
            }
            average /= this.points.children.length
            return average
        } else {
            return 0
        }
    }

    public sortValues(a: string, b: string) {
        const isForce = (col: string) => col === 'ForceMax' || col === 'Force'

        if (isForce(a)) {
            return 1
        } else if (isForce(b)) {
            return -1
        } else if (a.startsWith('D') && b.startsWith('D')) {
            const aNum = Number(a.split('D')[1])
            const bNum = Number(b.split('D')[1])
            if (aNum <= bNum) {
                return -1
            } else {
                return 1
            }
        }
        return -1
    }
}
