





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { colors } from '@/ts/data/enums';
import { Project } from '@/ts/project';

import IconBase from '@/components/IconBase.vue';
import ButtonColor from './ButtonColor.vue';

@Component({
    components: {
        ButtonColor,
        IconBase,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private colorName!: string;

    private readonly PALETTE = [
        colors.blue,
        colors.green,
        colors.yellow,
        colors.orange,
        colors.red,
        colors.purple,
        colors.grey,
        colors.white,
    ];

    private showPalette: boolean = false;
}

