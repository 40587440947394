import * as Mapbox from 'mapbox-gl'
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'

import { colors } from './data/enums'
import { Point } from './point'
import { Threshold } from './threshold'

export class Line {
    public hidden: boolean = false

    private _features: any[] = []

    private _id: string = String(Math.random())

    private _points: Point[]

    constructor(map: Mapbox.Map, points: Point[]) {
        this._points = points
        this.addTo(map)
    }

    public addTo(map: Mapbox.Map): void {
        map.addLayer({
            id: this._id,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-width': 6,
            },
            source: ({
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: this._features,
                },
            } as object) as Mapbox.GeoJSONSource,
            type: 'line',
        })
    }

    public clean(map: Mapbox.Map): void {
        if (map.getLayer(this._id)) {
            map.removeLayer(this._id)
            map.removeSource(this._id)
        }
    }

    public toggle(map: Mapbox.Map): void {
        this.hidden = !this.hidden
        if (map.getLayer(this._id)) {
            map.removeLayer(this._id)
            map.removeSource(this._id)
        } else {
            this.addTo(map)
        }
    }

    public async update(map: Mapbox.Map, threshold: Threshold, id: string) {
        let oldPoint: Point | undefined
        let i: number = 0
        while (!oldPoint && i < this._points.length) {
            if (!this._points[i].isHidden()) {
                oldPoint = this._points[i]
            } else {
                i++
            }
        }

        this._features = []
        for (i; i < this._points.length; i++) {
            if (oldPoint && !this._points[i].isHidden()) {
                let color: string = colors.white
                color = threshold.getColor(this._points[i].values[id].value.toNumber())

                this._features.push({
                    type: 'Feature',
                    properties: {
                        color,
                    },
                    geometry: {
                        type: 'LineString',
                        coordinates: [
                            [oldPoint._marker.getLngLat().lng, oldPoint._marker.getLngLat().lat],
                            [
                                this._points[i]._marker.getLngLat().lng,
                                this._points[i]._marker.getLngLat().lat,
                            ],
                        ],
                    },
                })

                oldPoint = this._points[i]
            }
        }

        if (map.getSource(this._id) as Mapbox.GeoJSONSource) {
            ;(map.getSource(this._id) as Mapbox.GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: this._features,
            } as FeatureCollection<Geometry, GeoJsonProperties>)
        }
    }
}
