






import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { saveAs } from 'file-saver'

import { Factory } from '@/ts/factory'
import { Project } from '@/ts/project'
import { Saver } from '@/ts/saver'

import Button from './Button.vue'

@Component({
    components: {
        Button,
    },
})
export default class C extends Vue {
    @State('factory')
    private factory!: Factory

    @State('project')
    private project!: Project

    private async saveReport(): Promise<any> {
        const file: File | undefined = await Saver.databaseToRptz(
            this.factory.database,
            this.factory.order,
            this.project,
            this.factory.name,
            this.project.currentTemplate ? this.project.currentTemplate.name : undefined,
            this.project.currentTemplate ? this.project.currentTemplate.content : undefined
        )

        if (file) {
            // console.log(this.factory.database);
            console.timeLog('save')
            saveAs(file, file.name)
            console.timeLog('save')
        }
    }
}
