

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import dragula from 'dragula-with-animation';
import uuid from 'uuid/v4';

import { Project } from '@/ts/project';

import TableHead from './components/Head.vue';
import TableLine from './components/Line.vue';

@Component({
    components: {
        TableHead,
        TableLine,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project;

    @Prop()
    private columns!: string[];

    private uuid: string = uuid();

    private mounted() {
        const container = document.getElementById('container');
        if (container) {
            dragula([container], {
                moves: (el: any, c: any, handle: HTMLElement) => handle.id === 'dragula'
                    || (handle.parentElement as HTMLElement).id === 'dragula'
                    || (handle.parentElement as any).parentElement.id === 'dragula',
            }).on('drop', (element: any, t: any, s: any, neighbor: any) => {
                if (this.project.reports.current) {
                    const elementNumber = element.textContent.trim().split(' ')[0];
                    const neighborNumber = neighbor ? neighbor.textContent.trim().split(' ')[0] : this.project.reports.current.points.size() + 1;

                    this.project.reports.current.points.changePointNumber(
                        Number(elementNumber),
                        Number(neighborNumber),
                    );

                    this.uuid = uuid();
                    this.project.update();
                }
            });
        }
    }
}

