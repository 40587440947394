import { colors } from './data/enums';
import { blend } from './utils/blender';
import { Unit } from './unit';

export enum STATE {
    GRADIENT = 0,
    DOUBLE = 1,
    TRIPLE = 2,
}

export class Threshold {
    public name: string;

    public badColor: string = colors.red;

    public badValue: AdvancedType;

    public goodColor: string = colors.green;

    public goodValue: AdvancedType;

    public max: number | undefined;

    public middleColor: string = colors.yellow;

    public min: number | undefined;

    public state: STATE = STATE.DOUBLE;

    public step: number | undefined;

    constructor(
        name: string,
        force: string,
        value: number,
        value2?: number,
        min?: number,
        max?: number,
    ) {
        this.name = name;
        this.min = min;
        this.max = max;

        if (! value2) {
            value2 = value;
        }

        this.goodValue = {
            kind: 'unit',
            value: new Unit(value, force),
        };

        this.badValue = {
            kind: 'unit',
            value: new Unit(value2, force),
        };
    }

    public getColor(number: number): string {
        let color: string = this.badColor;

        if (number < this.goodValue.value.toNumber()) {
            color = this.goodColor;
        } else if (number < this.badValue.value.toNumber()) {
            if (this.state === STATE.TRIPLE) {
                color = this.middleColor;
            } else if (this.state === STATE.GRADIENT) {
                color = blend(this.badColor,
                    this.goodColor,
                    (number - this.badValue.value.toNumber())
                    / (this.goodValue.value.toNumber() - this.badValue.value.toNumber()));
            }
        }

        return color;
    }
}
