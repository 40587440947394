


























import { Component, Prop, Vue } from 'vue-property-decorator';

import { styles } from '@/ts/data/enums';

import Tile from '@/components/Tile.vue';

@Component({
    components: {
        Tile,
    },
})
export default class C extends Vue {
    private styles: typeof styles = styles;
}

