












































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class C extends Vue {
    // @Prop({ default: false })
    // private isCentered!: boolean;

    // @Prop({ default: false })
    // private isFullWidth!: boolean;

    // @Prop({ default: false })
    // private isLarge!: boolean;

    // @Prop({ default: '' })
    // private selected!: string;

    private isActive: boolean = false;
}

