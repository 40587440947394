




































import {
    defineComponent,
    onMounted,
    onBeforeUnmount,
    ref,
} from '@vue/composition-api';

import Map from './components/Map.vue';
import Menu from './components/Menu/index.vue';

export default defineComponent({
    components: {
        Map,
        Menu,
    },
    setup() {
        const windowWidth = ref(window.innerWidth);

        const handleResize = () => {
            windowWidth.value = window.innerWidth;
        };

        onMounted(() => {
            const html = document.documentElement; // returns the html tag
            html.setAttribute('lang', navigator.language);
            window.addEventListener('resize', handleResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        return {
            windowWidth,
        };
    },
});
