








import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import html2canvas from 'html2canvas'

import { Project } from '@/ts/project'

import Button from './Button.vue'
import CaptureOverlay from './CaptureOverlay.vue'

@Component({
    components: {
        Button,
        CaptureOverlay,
    },
})
export default class C extends Vue {
    @State('project')
    private project!: Project

    private img: string | null = null

    private shot(): void {
        const map: HTMLElement | null = document.getElementById('map')
        if (this.project._mapview && this.project._mapview._map && map !== null) {
            map.getElementsByClassName('mapboxgl-control-container')[0].classList.add('hidden')
            map.classList.remove('rounded-xl')
            html2canvas(map, { logging: false })
                .then((canvas) => {
                    this.img = canvas.toDataURL()
                })
                .finally(() => {
                    map.classList.add('rounded-xl')
                    map.getElementsByClassName(
                        'mapboxgl-control-container'
                    )[0].classList.remove('hidden')
                })
        }
    }
}
