




















































/*
 * Vue 3 composition API doc:
 * https://vue-composition-api-rfc.netlify.com/#summary
 * https://dev.to/manuelojeda/how-to-use-vue-composition-api-using-typescript-part-3-22e6
 * https://vuedose.tips/tips/easily-switch-to-composition-api-in-vuejs-3/
 */

import {
    defineComponent,
    ref,
    // watch,
} from '@vue/composition-api';

import IconBase from '@/components/IconBase.vue';

export default defineComponent({
    components: {
        IconBase,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        startsOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const toggle = ref(false);
        toggle.value = props.startsOpen;

        const updateToggle = () => {
            toggle.value = ! toggle.value;
            emit('toggle', toggle.value);
        };

        // const toggleWatch = watch(toggle, (newVal, oldVal) => {
        //     console.log('Toggle changed', newVal, oldVal);
        // });

        return {
            toggle,
            updateToggle,
            // toggleWatch,
        };
    },
});

