



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { Project } from '@/ts/project';
import { blend } from '@/ts/utils/blender';
import { Unit } from '@/ts/unit';

@Component
export default class C extends Vue {
    @State('project')
    private project!: Project;

    private blend = blend;

    private report: any = null;

    private mounted() {
        this.report = (this.project as any).reports.current;
    }

    averageToString(average: number) {
        const UNIT: string = this.report.points.children[0].values[this.report.values.current].value.toUnit();
        return new Unit(average, UNIT);
    }
}

