



































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { Factory } from '@/ts/factory'
import { Project } from '@/ts/project'

import Header from '@/components/Header.vue'

import DragAndDrop from './components/DragAndDrop.vue'

@Component({
  components: {
    DragAndDrop,
    Header
  }
})
export default class C extends Vue {
  @State('factory')
  private factory!: Factory

  @State('project')
  private project!: Project

  private demo(): void {
    const getBrowserLocale = (languageCodeOnly = false) => {
      if (!navigator.language) {
        return undefined
      }

      const trimmedLocale = navigator.language.trim()

      return languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale
    }

    const locale = getBrowserLocale(true) === 'fr' ? 'FR' : 'EN'

    fetch(`https://mapview.fr/Demo Heavydyn ${locale}.rptz`)
      .then(response => response.blob())
      .then(blob => {
        const file: File = new File([blob], 'demo.rptz')
        this.factory.import([file], this.project)
      })
  }

  private async upload(files: File[]) {
    this.factory.import(files, this.project)
  }
}
