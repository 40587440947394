import * as Mapbox from 'mapbox-gl'
import { Point } from './point'
import { Threshold } from './threshold'

export class Points {
    public children: Point[] = []

    public icon: string = 'circle'

    public _blankNumber: boolean = false

    public _blankValue: boolean = true

    public _hidden: boolean = false

    public _movable: boolean = false

    public averageColor: string = ''

    public addToMap(map: Mapbox.Map): void {
        this.children.forEach((point: Point) => {
            point._marker.addTo(map)
        })
    }

    public addPoint(point: Point): void {
        this.children.push(point)
    }

    public bound(map: Mapbox.Map): void {
        const BOUNDS: Mapbox.LngLatBounds = new Mapbox.LngLatBounds()
        this.children.forEach((point: Point) => {
            BOUNDS.extend(point._marker.getLngLat())
        })
        map.fitBounds(BOUNDS, { padding: 100 })
    }

    public changePointNumber(from: number, to: number) {
        if (to < from) {
            this.children.forEach((point: Point) => {
                if (point.getNumber() >= to && point.getNumber() < from) {
                    point.setNumber(point.getNumber() + 1)
                } else if (point.getNumber() === from) {
                    if (point.isHidden()) {
                        point.setNumber(from)
                    } else {
                        point.setNumber(to)
                    }
                }
            })
        } else if (from < to) {
            this.children.forEach((point: Point) => {
                if (point.getNumber() > from && point.getNumber() < to) {
                    point.setNumber(point.getNumber() - 1)
                } else if (point.getNumber() === from) {
                    point.setNumber(to - 1)
                }
            })
        }

        this.sort()
    }

    public hide(point: Point): void {
        point.hide(this._hidden)

        if (point.isHidden()) {
            this.children.forEach((p: Point) => {
                if (this.children.indexOf(p) > this.children.indexOf(point)) {
                    if (p.getNumber() > point.getNumber()) {
                        p.setNumber(p.getNumber() - 1)
                    }
                }
            })
        } else {
            this.children.forEach((p: Point) => {
                if (this.children.indexOf(p) > this.children.indexOf(point)) {
                    if (p.getNumber() >= point.getNumber()) {
                        p.setNumber(p.getNumber() + 1)
                    }
                }
            })
        }
    }

    public sort(): void {
        const SORT_FUNCTION = (p1: Point, p2: Point) => {
            const N1: number = p1.getNumber()
            const N2: number = p2.getNumber()
            if (N1 > N2) {
                return 1
            } else if (N1 < N2) {
                return -1
            } else if (!p1.isHidden() && p2.isHidden()) {
                return 1
            } else if (p1.isHidden() && !p2.isHidden()) {
                return -1
            }
            return 0
        }
        this.children.sort(SORT_FUNCTION)
    }

    public toggleMovable(): void {
        this._movable = !this._movable
        this.children.forEach((point: Point) => {
            point._marker.setDraggable(this._movable)
        })
    }

    public toggleNumber(): void {
        this._blankNumber = !this._blankNumber

        this._blankValue = true

        this.children.forEach((point: Point) => {
            point.toggleNumber(this._blankNumber)
        })
    }

    public toggleValue(name: string): void {
        this._blankValue = !this._blankValue

        this._blankNumber = true

        this.children.forEach((point: Point) => {
            point.toggleValue(this._blankValue, name)
        })
    }

    public async update(threshold: Threshold, id: string) {
        this.children.forEach((point: Point) => {
            point.update(threshold, id, this.icon, this._hidden)
        })
    }

    public size(): number {
        return this.children.length
    }
}
